import { useState, useEffect } from 'react';

import SiteContainer from './../SiteContainer';
import PhotosCarousel from './PhotosCarousel';
import AddForm from './AddForm';

import { getBase64FromPicName, getMonthAndYearFromString } from "../../others/Utils";
import Spinner from '../../others/Spinner';
import api from "../../api_routes/api";

import '../../../styles/photos/photos.scss';

const title = "Photos | Goûts et traditions";
const tab = "pho";
const photoLoadFreq = 10;
var errorToMsg = {
    "initial_display" : "Une erreur interne est survenue lors de l'affichage des photos, veuillez nous excuser pour la gêne occasionnée.",
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée."
}

function Photos() {

    const [photos, setPhotos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [carouselInfos, setCarouselInfos] = useState({
        show: false,
        index: 0
    });
    const [showAddForm, setShowAddForm] = useState(false);

    useEffect(() => {

        document.title = title;

        const getPhotos = async () => {

            setIsError(false);
            setIsLoading(true);

            try {


                let res = await api.getPhotos();

                console.log(res);

                if(res?.data?.code === 0 && res.data?.photos) {

                    let i;
                    for(i=0; i < res.data.photos.length; i++) {

                        res.data.photos[i].base64Image = await getBase64FromPicName( res.data.photos[i].picName );
                        res.data.photos[i].isSelected = false;

                        if(i > 0 && (i % photoLoadFreq) === 0) {
                            setPhotos(currPhotos => {
                                return [...currPhotos].concat(res.data.photos.slice((i - photoLoadFreq), i));
                            });
                        }
                    }

                    setPhotos(currPhotos => {
                        return [...currPhotos].concat(res.data.photos.slice((i % photoLoadFreq) === 0 ? (i - photoLoadFreq) : (i - (i % photoLoadFreq)), i));
                    });

                } else
                    setIsError(true);

            } catch (error) {

                console.log(error);
                setIsError(true);

            }

            setIsLoading(false);

        }

        getPhotos();

        return () => {
            setPhotos([]);
        }

    }, []);

    const photosToJSX = function() {
        
        let curr_date = "";
        return photos.map((photo, index) => {

            let pdate = getMonthAndYearFromString(photo.date);

            if(curr_date !== pdate) {

                curr_date = pdate;

                return [
                    <div className="date" key={pdate}>{pdate}</div>,
                    <div className="photo-wrapper" key={photo._id} onClick={(e) => setCarouselInfos({ show: true, index }) }>
                        <img src={photo.base64Image} alt="" />
                    </div>
                ]

            } else {

                return (
                    <div className="photo-wrapper" key={photo._id} onClick={(e) => setCarouselInfos({ show: true, index }) }>
                        <img src={photo.base64Image} alt="" />
                    </div>
                )

            }

        });

    };

    const closeCarousel = function(e) {
        e.stopPropagation();
        setCarouselInfos({ ...carouselInfos, show: false });
    };

	return (

        <SiteContainer tab={tab} >

            <div className="photos-page">

                <div className="add-btn" onClick={() => setShowAddForm(true) }>Ajouter une photo</div>

                {showAddForm && <AddForm setShowAddForm={setShowAddForm} />}

                {isError ? 
                    <p className="message">{errorToMsg["initial_display"]}</p> : 
                    photos.length >= 0 ? (

                        <>
                        
                            <div className="photos-section">
                                {photosToJSX()}
                            </div>

                            {carouselInfos.show &&
                                <div className="carousel-wrapper" onClick={(e) => closeCarousel(e) }>
                                    <div className="carousel-section" onClick={(e) => e.stopPropagation() }>
                                        <div className="cross" onClick={(e) => closeCarousel(e) }>
                                            <div className="mdiv"></div><div className="md"></div>
                                        </div>
                                        <PhotosCarousel isLoading={isLoading} photos={photos} carouselInfos={carouselInfos} setCarouselInfos={setCarouselInfos} />
                                    </div>
                                </div>
                            }

                        </>

                    ) : (
                        !isLoading && <p className="message">Aucune photo pour le moment.</p>
                    )
                }

                {isLoading && <Spinner />}

            </div>

        </SiteContainer>
	);
}

export default Photos;
