import { useState, useEffect, useContext } from 'react'
import { Navigate } from 'react-router-dom';

import Header, { toggleRespMenu } from './Header';

import { LoginContext } from '../App';

import '../../styles/user/site-container.scss';

const loginPageUri = "/";

function SiteContainer(props) {

    const { loggedIn } = useContext(LoginContext);
    const [respMenu, setRespMenu] = useState({ active: false });
    const [respContScrollTop, setRespContScrollTop] = useState(0);

    useEffect(() => {

        toggleRespMenu(false, respMenu, setRespMenu);

        const respContent = document.querySelector(".site-content-wrapper");
        respContent?.addEventListener("scroll", () => { setRespContScrollTop(respContent.scrollTop); });
        window.addEventListener('resize', () => { setRespContScrollTop(respContent.scrollTop); });

    }, []);

    if(!loggedIn)
        return <Navigate to={loginPageUri} />
    else
        return (

            <div className="site-container">

                <div className="site-pusher">

                    <Header 
                        tab={props.tab}
                        respMenu={respMenu}
                        setRespMenu={setRespMenu}
                        respContScrollTop={respContScrollTop}
                    />

                    <div className="site-content-wrapper">

                        <div className="site-content">

                            {props.children}

                        </div>

                    </div>

                    <div className="site-cache" onClick={(e) => toggleRespMenu(false, respMenu, setRespMenu)} ></div>

                </div>

            </div>

        );
}

export default SiteContainer;
