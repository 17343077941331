import { useRef, useState, useEffect } from 'react';

import api from "../api_routes/api";

var errorToMsg = {
    "1" : "Une erreur interne est survenue, veuillez nous excuser pour la gêne occasionnée.",
    "2" : "L'email indiqué est introuvable"
}

function ForgotPwdPopup({togglePopup}) {

    const emailRef = useRef(null);
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputError, setInputError] = useState(false);

    useEffect(() => {
        return () => {
            setSuccess(false);
        }
    }, [])

    const submitEmail = async function() {

        try {

            if(isLoading) {
                return;
            }
            if(emailRef.current.value === "") {
                setInputError(true);
                return;
            }

            setIsLoading(true);
            let res = await api.sendPwdRequest({ email: emailRef.current.value });
            setIsLoading(false);

            if(res?.data?.code === 0) {
                setSuccess(true);
            } else if(typeof errorToMsg[res?.data?.code] != "undefined") {
                alert(errorToMsg[res.data.code]);
            } else {
                alert(errorToMsg["1"]);
            }

        } catch (error) {
            console.error(error);
            alert(errorToMsg["1"]);
        }

    };

	return (

        <div className='mdp-page' onClick={() => togglePopup(false)}>

            <div className="mdp-form" onClick={(e) => e.stopPropagation()}>

                <div className="cross" onClick={() => togglePopup(false)}>
                    <div className="mdiv"></div>
                    <div className="md"></div>
                </div>
        
                {!success ? 
                    <>
                        <p>Veuillez entrer votre adresse email ci-dessous, nous vous enverrons un email afin de changer votre mot de passe.</p>
                        <input type="email" placeholder="Votre Email" ref={emailRef} className={inputError ? 'error' : ''} onChange={() => setInputError(currentInputError => { if(currentInputError) return !currentInputError; })} />
                        <div className="submit" onClick={() => submitEmail()}>Valider</div>
                        <div className="cancel" onClick={() => togglePopup(false)}>Annuler</div>
                    </>
                    :
                    <p>Un email a bien été envoyé à <span>{emailRef?.current?.value}</span>.<br/><br/>Veuillez cliquer sur le lien reçu afin de modifier votre mot de passe. N'oubliez pas de vérifier votre dossier spam si vous ne trouvez l'email.</p>
                    
                }

            </div>

        </div>

	);
}

export default ForgotPwdPopup;
