import { useState, useEffect } from 'react';

import SiteContainer from '../SiteContainer';
import ParticipateForm from './ParticipateForm';

import { getBase64FromPicName } from "../../others/Utils";
import Spinner from '../../others/Spinner';
import api from "../../api_routes/api";

import calendar from '../../../assets/calendar.png';
import location from '../../../assets/location.svg';

import '../../../styles/rencontres/rencontres.scss';

const title = "Évènements | Goûts et traditions";
const tab = "eve";
var errorToMsg = {
    "initial_display" : "Une erreur interne est survenue lors de l'affichage des rencontres, veuillez nous excuser pour la gêne occasionnée.",
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.",
    "2": "Les stocks disponibles ne permettent pas d'ajouter ce produit au panier."
}

function Rencontres() {

    const [isLoading, setIsLoading] = useState(false);
    const [rencontres, setRencontres] = useState([]);
    const [isError, setIsError] = useState(false);
    const [participateForm, setParticipateForm] = useState({
        showForm : false,
        rencontre: null,
        participantNumber: 1
    });

    useEffect(() => {

        document.title = title;

        const getEvents = async () => {

            setIsError(false);
            setIsLoading(true);

            try {


                let res = await api.getEvents();

                if(res?.data?.code === 0 && res.data?.events) {

                    for(var i=0; i < res.data.events.length; i++) {

                        res.data.events[i].showUsersList = false;

                        res.data.events[i].base64Image = await getBase64FromPicName( res.data.events[i].picName );

                        res.data.events[i].totalParticipants = 0;
                        res.data.events[i].participate = false;

                        for(var j=0; j < res.data.events[i]._users.length; j++) {

                            if(res.data.events[i]._users[j].user) {

                                res.data.events[i].totalParticipants += res.data.events[i]._users[j].participantNumber;

                                if(res.data.user_id === res.data.events[i]._users[j].user._id)
                                    res.data.events[i].participate = true;

                            }

                        }

                    }

                    setRencontres(res.data.events);

                } else
                    setIsError(true);

            } catch (error) {

                console.log(error);
                setIsError(true);

            }

            setIsLoading(false);

        }

        getEvents();

    }, []);

    const toggleUsersList = function(rencontre_id) {


        for(var i=0; i < rencontres.length; i++) {

            if(rencontres[i]._id === rencontre_id) {

                rencontres[i].showUsersList = !rencontres[i].showUsersList;

                setRencontres(JSON.parse(JSON.stringify(rencontres)));

                break;

            }

        }

    };

    const showForm = function(rencontre) {

        setParticipateForm({
            showForm : true,
            rencontre,
            participantNumber: 1
        });

    };

    const unsubscribe = async function(rencontre_id) {

        try {

            let res = await api.unsubscribe({
                rencontre_id: rencontre_id,
            });

            if(res?.data?.code === 0 && res.data?.user?._id) {

                for(var i=0; i < rencontres.length; i++) {

                    if(rencontres[i]._id === rencontre_id) {

                        for(var j=0; j < rencontres[i]._users.length; j++) {

                            if(rencontres[i]._users[j].user && rencontres[i]._users[j].user._id === res.data.user._id) {

                                rencontres[i].totalParticipants -= rencontres[i]._users[j].participantNumber;
                                rencontres[i]._users.splice(j, 1);
                                break;

                            }

                        }

                        rencontres[i].participate = false;

                        setRencontres(JSON.parse(JSON.stringify(rencontres)));

                        break;

                    }

                }
                
            } else
                alert(
                    typeof errorToMsg[res?.data?.code] != "undefined" ? 
                        errorToMsg[res.data.code] :
                        errorToMsg["1"]
                );

        } catch (error) {

            console.log(error);
            alert(errorToMsg["1"]);

        }

    };

	return (

        <SiteContainer tab={tab} >

            <div className="items-page events-page">

                {isError && <p className="message">{errorToMsg["initial_display"]}</p>}

                {isLoading ? (
                    <Spinner />
                ) : (
                    rencontres.length === 0 ? (
                        !isError && <p className="message">Aucun événement n'est disponible</p>
                    ) : (
                        <>
                            <ul className="items-list">

                                {rencontres.map(rencontre => (

                                    <li key={rencontre._id}>

                                        <div className="item-img"><img src={rencontre.base64Image} alt="" /></div>

                                        <div className="item-desc">
                                            <div className="fline">
                                                <div className="title">
                                                    <p>{rencontre.title}</p>
                                                </div>
                                                <div className="price">
                                                    <p>{rencontre.price}€</p>
                                                </div>
                                            </div>
                                            <div className="desc">
                                                <p>{rencontre.desc}</p>
                                            </div>
                                            <div className="date">
                                                <img src={calendar} alt="" />
                                                <p>{rencontre.eventDate}</p>
                                            </div>
                                            <div className="location">
                                                <img src={location} alt="" />
                                                <p>{rencontre.location === "" ? "-" : rencontre.location}</p>
                                            </div>
                                        </div>

                                        {rencontre.participate ?
                                            (<div className="unsusc" onClick={(e) => unsubscribe(rencontre._id)}><p>Se désinscrire</p></div>) :
                                            (<div className="item-btn" onClick={(e) => showForm(rencontre)}><p>Participer</p></div>)
                                        }

                                        <div className="users" onClick={(e) => toggleUsersList(rencontre._id)} >
                                            <p>Voir les participants (<span>{rencontre.totalParticipants}</span>)</p>
                                        </div>

                                        <div className={`users-list ${rencontre.showUsersList && 'show'}`}>

                                            <div className="cross" onClick={(e) => toggleUsersList(rencontre._id)}><div className="mdiv"></div><div className="md"></div></div>

                                            {rencontre._users.length > 0 ? (
                                                
                                                rencontre._users.map(user => (
                                                    user.user &&  
                                                    <p key={user.user._id}>{user.user.firstName} {user.user.lastName}{user.participantNumber > 1 && ' + ' + (user.participantNumber - 1)}</p>
                                                ))
                                                
                                            ) : (
                                                <div className="nobody"><p>Il n'y a aucun participant pour le moment</p></div>
                                            )}

                                        </div>

                                    </li>

                                ))}

                            </ul>
                            
                            {participateForm.showForm && 
                                <ParticipateForm participateForm={participateForm} setParticipateForm={setParticipateForm} rencontres={rencontres} setRencontres={setRencontres} />}

                        </>            
                    )
                )}

            </div>

        </SiteContainer>
	);
}

export default Rencontres;
