import { useState, useEffect } from 'react'

import AdminSiteContainer from '../AdminSiteContainer';
import Spinner from '../../others/Spinner';
import AddForm from './AddForm';
import EditForm from './EditForm';

import api from "../../api_routes/api";
import { getBase64FromPicName } from "../../others/Utils";

import '../../../styles/admin/events/events.scss';

const title = "Admin - events | G&T";
const tab = "event";

var errorToMsg = {
    "initial_display" : "Une erreur interne est survenue lors de l'affichage des êvénements, veuillez nous excuser pour la gêne occasionnée.",
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée."
};

function Event() {

    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [showAddForm, setShowAddForm] = useState(false);
    const [editForm, setEditForm] = useState({
        show: false,
        event: {}
    });


    useEffect(() => {

        document.title = title; 

        const getEvents = async () => {

            setIsError(false);

            try {

                let res = await api.getAdminEvents();

                console.log("res : ", res);

                if(res?.data?.code === 0 && res.data?.events) {

                    for(var i=0; i < res.data.events.length; i++)
                        res.data.events[i].base64Image = await getBase64FromPicName( res.data.events[i].picName );

                    setEvents(res.data.events);

                } else
                    setIsError(true);

            } catch (error) {

                console.log(error);
                setIsError(true);

            }

            setIsLoading(false);

        }

        getEvents();

    }, []);

    const startEditForm = function(event) {

        setEditForm({
            show: true,
            base64Image: event.base64Image, 
            event : {
                _id: event._id,
                title: event.title,
                desc: event.desc,
                price: (event.price).toString(),
                eventDate: (event.eventDate).toString(),
                location: (event.location).toString(),
                hide: (event.hide).toString()
            }
        })

    };

    const deleteEvent = async function(event) {

        if(window.confirm(`Êtes-vous sûr(e) de vouloir supprimer l'êvénement "${event.title}" ?`)) {
            try {

                let res = await api.deleteEvent({ _id: event._id });

                if(res.data.code === 0) {
                    window.location.reload(false);
                } else {
                    
                    if(typeof errorToMsg['' + res.data.code] !== "undefined")
                        alert(errorToMsg['' + res.data.code]);
                    else
                        alert(errorToMsg["1"]);

                }

            } catch (error) {

                console.error(error);
                alert(errorToMsg["1"]);

            }
        }

    };

	return (

        <AdminSiteContainer>

            <div className="events-section">

                <h1>Évènements</h1>

                <button onClick={() => setShowAddForm(true) }>Ajouter un évènement</button>

                {isError && <p className="message">{errorToMsg["initial_display"]}</p>}

                {isLoading ? (
                    <Spinner />
                ) : (

                    events.length === 0 ? (
                        <p className="message">{!isError ? "Aucun êvénement" : ""}</p>
                    ) : (

                        <table className = "events-list">
                            <tbody>

                            <tr>
                                <th></th>
                                <th>Titre</th>
                                <th>Description</th>
                                <th>Prix</th>
                                <th>Date</th>
                                <th>Lieu</th>
                                <th>Caché</th>
                                <th>Participants</th>
                                <th>Actions</th>
                            </tr>

                                {events.map(event => (

                                    <tr key={event._id}>

                                        <td className='img-wrapper'><img src={event.base64Image} alt="" /></td>
                                        <td>{event.title}</td>
                                        <td>{event.desc}</td>
                                        <td>{event.price}€</td>
                                        <td>{event.eventDate}</td>
                                        <td>{event.location}</td>
                                        <td>{event.hide ? "Oui" : "Non"}</td>

                                        <td>

                                            {event._users.length > 0 ? 
                                                (event._users.map(user => {
                                                    return <p key={user._id}>{user.user?.firstName} {user.user?.lastName}{user.participantNumber > 1 ? (" +" + (user.participantNumber - 1)) : ""}</p>
                                                }))
                                                : (<p>Aucun participant</p>)
                                            }
                                       </td>


                                        <td className="actions">

                                            <div className="cross" onClick={() => deleteEvent(event)}>
                                                <div className="mdiv"></div>
                                                <div className="md"></div>
                                            </div>

                                            <div className='edit-btn' onClick={() => startEditForm(event)} >Modifier</div>

                                        </td>

                                    </tr>

                                ))}

                            </tbody>
                        </table>

                    )

                )}

            </div>

            {showAddForm && <AddForm setShowAddForm={setShowAddForm} />}
            {editForm.show && <EditForm editForm={editForm} setEditForm={setEditForm} />}

        </AdminSiteContainer>

	);
}

export default Event;
