import { useState, useEffect } from 'react'

import AdminSiteContainer from '../AdminSiteContainer';
import Spinner from '../../others/Spinner';
import AddForm from './AddForm';
import EditForm from './EditForm';

import api from "../../api_routes/api";

import '../../../styles/admin/users/users.scss';

const title = "Admin - Utilisateurs | G&T";
const tab = "user";

var errorToMsg = {
    "initial_display" : "Une erreur interne est survenue lors de l'affichage des utilisateurs, veuillez nous excuser pour la gêne occasionnée.",
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée."
}

function Users() {

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const [showAddForm, setShowAddForm] = useState(false);
    const [editForm, setEditForm] = useState({
        show: false,
        email: "",
        firstName: "",
        lastName: "",
        admin: ""
    });


    useEffect(() => {

        document.title = title; 

        const getUsers = async () => {

            setIsError(false);
            setIsLoading(true);

            try {


                let res = await api.getUsers();

                if(res?.data?.code === 0 && res.data?.users) {

                    setUsers(res.data.users);

                } else
                    setIsError(true);

            } catch (error) {

                console.log(error);
                setIsError(true);

            }

            setIsLoading(false);

        }

        getUsers();

    }, []);

    const startEditForm = function(user) {

        setEditForm({
            show: true,
            _id: user._id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            admin: user.admin
        })

    };

    const deleteUser = async function(user) {

        if(window.confirm(`Êtes-vous sûr(e) de vouloir supprimer l'utilisateur ${user.email} ?`)) {

            try {

                let res = await api.deleteUser({ _id: user._id });

                if(res.data.code === 0) {

                    window.location.reload(false);

                } else {
                    
                    if(typeof errorToMsg['' + res.data.code] !== "undefined")
                        alert(errorToMsg['' + res.data.code]);
                    else
                        alert(errorToMsg["1"]);

                }

            } catch (error) {

                console.error(error);
                alert(errorToMsg["1"]);

            }

        }

    };

	return (

        <AdminSiteContainer>

            <div className="users-section">

                <h1>Utilisateurs</h1>

                <button onClick={() => setShowAddForm(true) }>Ajouter un utilisateur</button>

                {isError && <p className="message">{errorToMsg["initial_display"]}</p>}

                {isLoading ? (
                    <Spinner />
                ) : (

                    users.length === 0 ? (
                        <p className="message">{!isError ? "Aucun utilisateur" : ""}</p>
                    ) : (

                        <table className = "users-list">
                            <tbody>

                                <tr>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Email</th>
                                    <th>Admin</th>
                                    <th>Actions</th>
                                </tr>

                                {users.map(user => (

                                    <tr key={user._id}>

                                        <td>{user.lastName}</td>
                                        <td>{user.firstName}</td>
                                        <td>{user.email}</td>
                                        <td>{user.admin ? "Admin" : "Membre"}</td>

                                        <td className="actions">

                                            <div className="cross" onClick={() => deleteUser(user)}>
                                                <div className="mdiv"></div>
                                                <div className="md"></div>
                                            </div>

                                            <div className='edit-btn' onClick={() => startEditForm(user)} >Modifier</div>

                                        </td>

                                    </tr>

                                ))}

                            </tbody>
                        </table>

                    )

                )}

            </div>

            {showAddForm && <AddForm setShowAddForm={setShowAddForm} />}
            {editForm.show && <EditForm editForm={editForm} setEditForm={setEditForm} />}

        </AdminSiteContainer>

	);
}

export default Users;
