import { Carousel } from "react-responsive-carousel";

import Spinner from '../../others/Spinner';
import { formatDateFromString } from "../../others/Utils";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../../styles/photos/photos-carousel.scss';

function PhotosCarousel({isLoading, photos, carouselInfos, setCarouselInfos}) {

    // if(isLoading)
    if(photos.length === 0)
        return <Spinner />
    else
        return (

            <Carousel
                className="carousel"
                infiniteLoop={true}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                swipeable={true}
                emulateTouch={true}
                selectedItem={carouselInfos.index}
                renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                    <div className='arrow-wrapper left' onClick={clickHandler}><div className="arrow"></div></div>
                }
                renderArrowNext={(clickHandler, hasNext, labelNext) =>
                    <div className='arrow-wrapper right' onClick={clickHandler}><div className="arrow"></div></div>
                }
            >

                {photos.map(photo => (
                    <>
                        <div key={photo._id} className="slider-item">
                            <img src={photo.base64Image} alt="" />
                        </div>
                        <div className="meta">
                            <p className="desc">{photo.desc}</p>
                            <p>Publié le {formatDateFromString(photo.date)}{photo.user && " par " + photo.user.firstName + " " + photo.user.lastName}</p>
                        </div>
                    </>
                ))}

            </Carousel>

        );

}

export default PhotosCarousel;