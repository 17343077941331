import { useState, useEffect, useContext } from 'react'

import SiteContainer from './SiteContainer';
import Spinner from '../others/Spinner';

import api from "../api_routes/api";
import { getBase64FromPicName, updateCart, typeToLabel } from "../others/Utils";

import { LoginContext } from '../App';

import '../../styles/items.scss';
import '../../styles/articles/articles.scss';

const title = "Articles | Goûts et traditions";
const tab = "art";

var errorToMsg = {
    "initial_display" : "Une erreur interne est survenue lors de l'affichage des articles, veuillez nous excuser pour la gêne occasionnée.",
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.",
    "2": "Les stocks disponibles ne permettent pas d'ajouter ce produit au panier."
}

function Articles() {

    const [articles, setArticles] = useState([]);
    const [type, setType] = useState("all");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const { setCartCount } = useContext(LoginContext);

    useEffect(() => {

        document.title = title;

        const getArticles = async () => {

            setIsError(false);
            setIsLoading(true);

            try {

                let res = await api.getArticles({ type });

                if(res?.data?.code === 0 && res.data?.articles) {

                    for(var i=0; i < res.data.articles.length; i++) {
                        res.data.articles[i].base64Image = await getBase64FromPicName( res.data.articles[i].picName );
                        res.data.articles[i].checkButton = false;
                        res.data.articles[i].qtyToAdd = 1;
                    }

                    setArticles(res.data.articles);

                } else
                    setIsError(true);

            } catch (error) {

                console.log(error);
                setIsError(true);

            }

            setIsLoading(false);

        }

        getArticles();

    }, [type]);

    const handleSelectChange = (e) => {
        setType(e.target.value);
    };

    const makeButtonCheckTemp = (index) => {

        const setCheckButton = (check) => {

            setArticles((currentArticles) => {
                return [
                    ...currentArticles.slice(0, index),
                    {
                        ...currentArticles[index],
                        checkButton : check
                    },
                    ...currentArticles.slice(index + 1)
                ]
            });

        };

        if(index !== -1) {

            setCheckButton(true);

            setTimeout(() => {
                setCheckButton(false);
            }, 2000);

        }

    };

    const addQty = async (article, index, qty) => {

        if(
            article.quantity > 0 &&
            (article.qtyToAdd + qty) > 0 &&
            (article.qtyToAdd + qty) <= article.quantity
        ) {

            setArticles((currentArticles) => {
                return [
                    ...currentArticles.slice(0, index),
                    {
                        ...currentArticles[index],
                        qtyToAdd : (article.qtyToAdd + qty)
                    },
                    ...currentArticles.slice(index + 1)
                ]
            });

        }

    };

    const addToCart = async (article, index) => {
        
        try {


            let res = await updateCart(article._id, article.qtyToAdd);

            if(res?.data?.code === 0 && res.data?.cart?.count) {

                setCartCount(parseInt(res.data.cart.count));
                makeButtonCheckTemp(index);
                
            } else
                alert(
                    typeof errorToMsg[res?.data?.code] != "undefined" ? 
                        errorToMsg[res.data.code] :
                        errorToMsg["1"]
                );

        } catch (error) {

            console.log(error);
            alert(errorToMsg["1"]);

        }

    };

	return (

        <SiteContainer tab={tab} >

            <div className="items-page articles-page">

                <select id="type" onChange={handleSelectChange}>
                    <option value="all">Aucun filtre</option>
                    <option value="stock">Vins en stock</option>
                    <option value="souscr">Vins en souscription</option>
                    <option value="divers">Divers</option>
                    <option value="adhesion">Adhesion</option>
                </select>

                {isError && <p className="message">{errorToMsg["initial_display"]}</p>}

                {isLoading ? (
                    <Spinner />
                ) : (
                    articles.length === 0 ? (
                        <p className="message">{!isError ? "Aucun article disponible" : ""}</p>
                    ) : (
                        <ul className="items-list" id = "items-list">
                            {articles.map((article, index) => (
                                <li key={article._id} className={article.quantity <= 0 ? "out-of-stock" : ""}>
                                    <div className="item-img"><img src={article.base64Image} alt="" /></div>
                                    <div className="item-desc">
                                        <div className="fline">
                                            <div className="title">
                                                <p>{article.title}</p>
                                            </div>
                                            <div className="price">
                                                <p>{article.price} €</p>
                                            </div>
                                        </div>
                                        <div className="desc">
                                            <p>{article.desc}</p>
                                        </div>
                                        <div className="quantity-wrapper">
                                            <div className="moins" onClick={(e) => addQty(article, index, -1)}>-</div>
                                            <div className='quantity'>{article.qtyToAdd}</div>
                                            <div className="plus" onClick={(e) => addQty(article, index, 1)}>+</div>
                                        </div>
                                    </div>
    
                                    {article.quantity <= 0 ?
                                        (   
                                            <div className="item-btn">
                                                <p>Stock épuisé</p>
                                            </div>
                                        ) : 
                                        (   
                                            <div className={article.checkButton ? "item-btn check" : "item-btn"} onClick={(e) => addToCart(article, index)}>
                                                <p>Ajouter au panier</p>
                                                <div className="checkmark-wrapper"><div className="checkmark"></div></div>
                                            </div>
                                        )
                                    }
   
                                </li>
                            ))}
                        </ul>
                    )
                )}

            </div>

        </SiteContainer>

	);
}

export default Articles;
