import api from "../api_routes/api";

export const getBase64FromPicName = async (picName) => {

    let imgResult= await api.getImg( picName );
    let base64 = btoa(
        new Uint8Array(imgResult.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
        )
    );

    return "data:;base64," + base64;

};

export const updateCart = async (articleId, quantity) => {

    let res = await api.updateCart({ 
        id: articleId,
        quantity
    });

    return res;

};

export const typeToLabel = {
    "stock": "Vin en stock",
    "souscr": "Vin en souscription",
    "divers": "Divers",
    "adhesion": "Adhesion"
};

export const formatDateHourFromString = function(str) {

    let date = new Date(str).toLocaleDateString('fr-fr', { weekday:"long", year:"numeric", month:"long", day:"numeric", hour: '2-digit', minute:'2-digit' });

    return date.charAt(0).toUpperCase() + date.slice(1);

};

export const formatDateFromString = function(str) {

    let date = new Date(str).toLocaleDateString('fr-fr', {year:"numeric", month:"long", day:"numeric" });

    return date.charAt(0).toUpperCase() + date.slice(1);

};

export const getMonthAndYearFromString = function(str) {

    let date = new Date(str).toLocaleDateString('fr-fr', { month:"long", year:"numeric" });

    return date.charAt(0).toUpperCase() + date.slice(1);

};