import api from "../../api_routes/api";

import calendar from '../../../assets/calendar.png';
import location from '../../../assets/location.svg';

var errorToMsg = {
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.",
    "2": "Vous participez déjà à cet évênement."
};

function ParticipateForm({participateForm, setParticipateForm, rencontres, setRencontres }) {

    const hideForm = function() {

        setParticipateForm({
            showForm: false,
            rencontre: null,
            participantNumber: 1
        });

    }; 

    const handleSelectChange = (e) => {

        setParticipateForm({...participateForm, participantNumber : parseInt(e.target.value) });
    
    };

    const participate = async function() {

        try {

            let res = await api.participate({
                rencontre_id: participateForm.rencontre._id,
                participantNumber: participateForm.participantNumber.toString()
            });

            if(res?.data?.code === 0 && res.data?.user) {

                for(var i=0; i < rencontres.length; i++) {

                    if(rencontres[i]._id === participateForm.rencontre._id) {

                        rencontres[i]._users.push({
                            participantNumber: participateForm.participantNumber,
                            user: res.data.user
                        });

                        rencontres[i].totalParticipants += participateForm.participantNumber;
                        rencontres[i].participate = true;

                        setRencontres(JSON.parse(JSON.stringify(rencontres)));
                        hideForm();

                        break;

                    }

                }
                
            } else
                alert(
                    typeof errorToMsg[res?.data?.code] != "undefined" ? 
                        errorToMsg[res.data.code] :
                        errorToMsg["1"]
                );

        } catch (error) {

            console.log(error);
            alert(errorToMsg["1"]);

        }

    };

    return (

        <div className="form-wrapper" onClick={(e) => hideForm()}>

            <div className="form" onClick={(e) => e.stopPropagation() }>

                <div className="cross" id="mdp-cross" onClick={(e) => hideForm()}>
                    <div className="mdiv"></div>
                    <div className="md"></div>
                </div>

                <div className="event-infos">
                    <p className="title">{participateForm.rencontre.title}</p>
                    <p className="desc">{participateForm.rencontre.desc}</p>
                    <div className="date">
                        <img src={calendar} alt="" />
                        <p>{participateForm.rencontre.eventDate}</p>
                    </div>
                    <div className="location">
                        <img src={location} alt="" />
                        <p>{participateForm.rencontre.location === "" ? "-" : participateForm.rencontre.location}</p>
                    </div>
                </div>

                <div className="form-content">

                    <p>Veuillez indiquer avec combien de personnes vous viendrez :</p>

                    <div className="select-wrapper">
                        <label>Je serai...</label>
                        <select onChange={handleSelectChange}>
                            <option value="1">Tout(e) seul(e)</option>
                            <option value="2">Avec 1 personne</option>
                            <option value="3">Avec 2 personnes</option>
                            <option value="4">Avec 3 personnes</option>
                            <option value="5">Avec 4 personnes</option>
                            <option value="6">Avec 5 personnes</option>
                        </select>
                    </div>

                    <div>
                        <div className="submit" id="submit" onClick={(e) => participate()}>Valider</div>
                        <div className="cancel" id="cancel" onClick={(e) => hideForm()} >Annuler</div>
                    </div>

                </div>

            </div>

        </div>

    );

}

export default ParticipateForm;