import { useState } from 'react'

import api from "../../api_routes/api";

import '../../../styles/admin/users/addform.scss';

var errorToMsg = {
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.",
    "2": "Cet email est déjà utilisé pour un autre utilisateur."
}

function AddForm({ setShowAddForm }) {

    const [formValues, setFormValues] = useState({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        admin: ""
    });

    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const validate = async () => {

        try {

            let res = await api.createUser({ ...formValues });

            if(res.data.code === 0) {

                window.location.reload(false);

            } else if (res.data.code === 3 && typeof res.data.error != "undefined")
                alert(res.data.error);
            else {
                
                if(typeof errorToMsg['' + res.data.code] !== "undefined")
                    alert(errorToMsg['' + res.data.code]);
                else
                    alert(errorToMsg["1"]);

            }

        } catch (error) {

            console.error(error);
            alert(errorToMsg["1"]);

        }

    };


	return (

        <div className='addform-wrapper' onClick={() => setShowAddForm(false) }>

            <div className='form-content' onClick={(e) => e.stopPropagation() }>

                <p className="title">Ajouter un utilisateur</p>

                <input type = "email" name="email" placeholder = "Email" onChange={(e) => handleChange(e)} />
                <input type = "password" name="password" placeholder = "Mot de passe" onChange={(e) => handleChange(e)} />
                <input type = "text" name="firstName" placeholder = "Prénom" onChange={(e) => handleChange(e)} />
                <input type = "text" name="lastName" placeholder = "Nom" onChange={(e) => handleChange(e)} />
                <label>
                    <input type="checkbox" name="admin" onChange={(e) => setFormValues({ ...formValues, admin: e.target.checked })} /> Admin
                </label>

                <div className="btns-wrapper">

                    <div className="btn-wrapper">
                        <button onClick={() => validate()} >Valider</button>
                    </div>

                    <div className="btn-wrapper">
                        <button onClick={() => setShowAddForm(false) } >Annuler</button>
                    </div>

                </div>

            </div>

        </div>

	);
}

export default AddForm;
