import { useState, useEffect } from 'react';
import moment from 'moment';

import AdminSiteContainer from '../AdminSiteContainer';
import Spinner from '../../others/Spinner';

import api from "../../api_routes/api";

import '../../../styles/admin/commandes/commandes.scss';

const title = "Admin - Commandes | G&T";
const tab = "com";

var errorToMsg = {
    "initial_display" : "Une erreur interne est survenue lors de l'affichage des commandes, veuillez nous excuser pour la gêne occasionnée.",
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée."
}

function Commandes() {

    const [commandes, setCommandes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);


    useEffect(() => {

        document.title = title; 

        const getCommandes = async () => {

            setIsError(false);
            setIsLoading(true);

            try {


                let res = await api.getCommandes();

                if(res?.data?.code === 0 && res.data?.commandes) {

                    console.log(res.data.commandes);

                    for(var i=0; i < res.data.commandes.length; i++) {

                        res.data.commandes[i].date = moment(new Date(res.data.commandes[i].date)).format('DD/MM/YYYY');
                        res.data.commandes[i].showStatusInput = false;
                        res.data.commandes[i].newStatus = res.data.commandes[i].statut;

                        res.data.commandes[i].total = 0;

                        for(var j=0; j < res.data.commandes[i].cmd.products.length; j++)
                            res.data.commandes[i].total += res.data.commandes[i].cmd.products[j].quantity * res.data.commandes[i].cmd.products[j].price; 


                    }

                    setCommandes(res.data.commandes);

                } else
                    setIsError(true);

            } catch (error) {

                console.log(error);
                setIsError(true);

            }

            setIsLoading(false);

        }

        getCommandes();

    }, []);


    const setShowStatusInput = function(commande) {

        for(var i=0; i < commandes.length; i++) {

            if(commandes[i]._id === commande._id) {

                commandes[i].showStatusInput = true;
                break;

            }

        }

        setCommandes( JSON.parse(JSON.stringify(commandes)) );

    };

    const changeStatus = function(e, commande) {

        for(var i=0; i < commandes.length; i++) {

            if(commandes[i]._id === commande._id) {

                commandes[i].newStatus = e.target.value;
                break;

            }

        }

        setCommandes( JSON.parse(JSON.stringify(commandes)) );

    }

    const saveNewStatus = async function(commande) {

        try {

            let res = await api.updateCommandeStatus({ 
                _id: commande._id,
                status: commande.newStatus
            });

            console.log(res);

            if(res.data.code === 0) {

                for(var i=0; i < commandes.length; i++) {

                    if(commandes[i]._id === commande._id) {
        
                        commandes[i].statut = commande.newStatus;
                        commandes[i].showStatusInput = false;
                        break;
        
                    }
        
                }

                setCommandes( JSON.parse(JSON.stringify(commandes)) );

            } else {

                if(typeof errorToMsg['' + res.data.code] !== "undefined")
                    alert(errorToMsg['' + res.data.code]);
                else
                    alert(errorToMsg["1"]);

            }

        } catch (error) {

            console.error(error);
            alert(errorToMsg["1"]);

        }

    }

    const deleteCommande = async function(commande) {

        if(window.confirm(`Êtes-vous sûr(e) de vouloir supprimer cette commande de ${commande.user?.firstName} ${commande.user?.lastName} ?\nCette action ajoutera les produits de cette commande en stock`)) {

            try {

                let res = await api.deleteCommande({ _id: commande._id });

                if(res.data.code === 0) {

                    window.location.reload(false);

                } else {

                    if(typeof errorToMsg['' + res.data.code] !== "undefined")
                        alert(errorToMsg['' + res.data.code]);
                    else
                        alert(errorToMsg["1"]);

                }

            } catch (error) {

                console.error(error);
                alert(errorToMsg["1"]);

            }

        }

    };

	return (

        <AdminSiteContainer>

            <div className="commandes-section">

                <h1>Commandes</h1>

                {isError && <p className="message">{errorToMsg["initial_display"]}</p>}

                {isLoading ? (
                    <Spinner />
                ) : (

                    commandes.length === 0 ? (
                        <p className="message">{!isError ? "Aucune commande à afficher" : ""}</p>
                    ) : (

                        <table className = "commandes-list">
                            <tbody>

                                <tr>
                                    <th>Date</th>
                                    <th>User</th>
                                    <th>Commande</th>
                                    <th>Prix</th>
                                    <th>Statut</th>
                                    <th>Actions</th>
                                </tr>

                                {commandes.map(commande => (

                                    <tr key={ commande._id }>

                                        <td>{ commande.date }</td>

                                        <td>
                                            {
                                                commande.user ? 
                                                    <>
                                                        <p>{ commande.user.firstName }</p>
                                                        <p>{ commande.user.lastName }</p>
                                                        <p>{ commande.user.email }</p>
                                                    </> : 
                                                    <p>Cet utilisateur a été supprimé</p>
                                            }
                                        </td>

                                        <td>

                                            {commande.cmd.products.map(product => (

                                                (product.quantity > 0) && 
                                                    <p key={product.id}>{product.quantity}x {product.title} <br></br> {product.desc}</p>

                                            ))}

                                        </td>

                                        <td>{ commande.total }€</td>
                                            
                                        <td>
                                            { 
                                                commande.showStatusInput ?
                                                    <input type="text" value={ commande.newStatus } onChange={(e) => changeStatus(e, commande)}/> :
                                                    commande.statut 
                                            }
                                        </td>

                                        <td className="actions">

                                            <div className="cross" onClick={() => deleteCommande(commande)}>
                                                <div className="mdiv"></div>
                                                <div className="md"></div>
                                            </div>

                                            {
                                                commande.showStatusInput ? 
                                                    <div className='save' onClick={() => saveNewStatus(commande)} >Enregistrer</div> :
                                                    <div className='modify' onClick={() => setShowStatusInput(commande)} >Modifier</div> 
                                            }

                                        </td>

                                    </tr>

                                ))}

                            </tbody>
                        </table>

                    )

                )}

            </div>

        </AdminSiteContainer>

	);
}

export default Commandes;
