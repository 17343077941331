import { useState, useEffect } from 'react'

import AdminSiteContainer from '../AdminSiteContainer';
import Spinner from '../../others/Spinner';
import EditForm from './EditForm';

import api from "../../api_routes/api";

import '../../../styles/admin/home_images/home_images.scss';

const title = "Admin - Images | G&T";
const tab = "img";

var errorToMsg = {
    "initial_display" : "Une erreur interne est survenue lors de l'affichage des images, veuillez nous excuser pour la gêne occasionnée.",
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée."
}

function Users() {

    const [pictures, setPictures] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [editForm, setEditForm] = useState({
        show: false,
        picture_title: ""
    });


    useEffect(() => {

        document.title = title; 

        const getLoginImgNames = async () => {

            try {

                let res = await api.getLoginImgNames();

                if(res?.data?.code === 0 && res.data?.pictures?.length > 0) {

                    setPictures(res.data.pictures);

                } else
                    setIsError(true);

            } catch (error) {

                console.log(error);
                setIsError(true);

            }

            setIsLoading(false);

        }

        getLoginImgNames();

    }, []);

    const showEditForm = function(picture_title) {

        setEditForm({
            show: true,
            picture_title : picture_title
        })

    };


	return (

        <AdminSiteContainer>

            <div className='img-section'>

                <h1>Images d'accueil</h1>

                {isLoading ? (
                    <Spinner />
                ) : (

                    isError ? (
                        <p>{errorToMsg["initial_display"]}</p>
                    ) : (

                        pictures.map(picture => (

                            <div key={picture._id} className={"img-wrapper " + picture.picName.split(".")[0]}>

                                <img src={process.env.REACT_APP_BASE_URL + "/img/allowed/" + picture.picName} alt="" />

                                <div className="img-mask">
                                    <div className="edit-btn" onClick={() => showEditForm(picture.title) }>Modifier</div>
                                </div>

                            </div>

                        ))
        

                    )

                )}

            </div>
            
            {editForm.show && <EditForm editForm={editForm} setEditForm={setEditForm} />}

        </AdminSiteContainer>

	);
}

export default Users;
