import { useState, useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom';

import api from "../api_routes/api";

import { LoginContext } from '../App';

// import CarouselWrapper from './CarouselWrapper';
import ForgotPwdPopup from './ForgotPwdPopup';

import logoHD from '../../assets/login/logo_GeT_HD.png';

import '../../styles/login/login.scss';

const title = "Login | Goûts et traditions";
const dashboardPageURI = '/articles';
const errorToMsg = {
    "1" : "Une erreur interne est survenue, veuillez nous excuser pour la gêne occasionnée.",
    "2" : "Votre email et/ou votre mot de passe est invalide.\nVeuillez vérifier vos identifiants et rééssayer."
}

function Login() {

    const { loggedIn, setLoggedIn, setIsAdmin } = useContext(LoginContext);
    const [formState, setFormState] = useState({ email: "", password: "" });
    const [showPopup, togglePopup] = useState(false);

    useEffect(() => {
        document.title = title;
    }, []);

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            const { email, password } = formState;
            let res = await api.login({ email, password });

            if(res.data.code === 0) {

                localStorage.setItem("accessToken", res.data.accessToken);
                localStorage.setItem("refreshToken", res.data.refreshToken);

                let admin = (typeof res.data.admin != "undefined" && res.data.admin === true) ? true : false;
                localStorage.setItem("admin", admin);

                setIsAdmin(admin);
                setLoggedIn(true);

            } else {
                
                if(typeof errorToMsg['' + res.data.code] !== "undefined")
                    alert(errorToMsg['' + res.data.code]);
                else
                    alert(errorToMsg["1"]);

            }

        } catch (error) {

            console.error(error);
            alert(errorToMsg["1"]);

        }

    };

    if(loggedIn)
        return <Navigate to={dashboardPageURI} />
    else
        return (
            <>

                {showPopup && <ForgotPwdPopup togglePopup={togglePopup} />}

                <div className="login-page">

                    <img className='background' src={`${process.env.REACT_APP_BASE_URL}/img/allowed/accueil2.jpeg`} alt="" />

                    <div className="form-wrapper">

                        <div className="form-section">

                            <img src={logoHD} alt="" className="logo-header" />

                            <div className="form">
                                <input type = "text" name = "email" onChange={(e) => handleChange(e)} placeholder = "Email" />
                                <input type = "password" name = "password" onChange={(e) => handleChange(e)} placeholder = "Mot de passe" />
                                <input type = "submit" value = "Valider" onClick={(e) => handleSubmit(e)} />
                            </div>
                            
                            <div className="oubli-mdp" id="oubli-mdp" onClick={() => togglePopup(true)}>MOT DE PASSE OUBLIE ?</div>
                            
                        </div>

                    </div>

                </div>
            </>
        );
}

export default Login;
