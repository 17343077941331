import { useState, useRef } from 'react'

import api from "../../api_routes/api";
import '../../../styles/photos/addform.scss';

import { MAX_FILE_SIZE_MB } from "../../others/Constants";

var errorToMsg = {
    "1": "Une erreur est survenue lors de l'envoi de votre photo, veuillez nous excuser pour la gêne occasionnée.",
    "file_too_large": `Votre photo est trop lourde, veuillez en sélectionner un autre de moins de ${MAX_FILE_SIZE_MB}mb s'il vous plaît.`
};

function AddForm({ setShowAddForm }) {

    const [image, setImage] = useState(null);
    const [inputErrors, setInputErrors] = useState([]);
    const desc = useRef(null);

    const validate = async () => {

        try {

            let _inputErrors = [];
            if(!image) {
                _inputErrors.push("image");
            }
            if(desc.current.value === "") {
                _inputErrors.push("desc");
            }

            if(_inputErrors.length > 0) {
                setInputErrors(_inputErrors);
            } else if((image.size / 1024) > (MAX_FILE_SIZE_MB * 1024)) {
                throw errorToMsg["file_too_large"];
            } else {

                var form = new FormData();

                form.append("image", image);
                form.append("desc", desc.current.value);

                let res = await api.addPhoto(form);

                if(res.data.code === 0) {
                    window.location.reload(false);
                } else if(typeof res.data.error != "undefined"){
                    alert(res.data.error);
                } else {
                    alert(errorToMsg["1"]);
                }

            }

        } catch (error) {

            console.error(error);
            alert(errorToMsg["1"]);

        }

    };


	return (

        <div className='addform-wrapper' onClick={() => setShowAddForm(false) }>

            <div className='form-content' onClick={(e) => e.stopPropagation() }>

                <div className="cross" id="mdp-cross" onClick={() => setShowAddForm(false) }>
                    <div className="mdiv"></div>
                    <div className="md"></div>
                </div>

                <p className="title">Ajouter une photo</p>

                <div className={inputErrors.includes('image') ? 'img-handler error': 'img-handler'}>

                    <input type="file" id="file" accept=".gif,.jpg,.jpeg,.png" onChange={(e) => { setInputErrors([]); setImage(e.target.files[0]); }} />

                    <div className="btn-wrapper">
                        <label htmlFor="file">Choisir une photo</label>
                        <p>Extensions supportées : png, jpg, jpeg et gif<br/>Taille maximale : {MAX_FILE_SIZE_MB}MB</p>
                    </div>

                    <div className="img-wrapper">
                        {image ?
                            (<img src={URL.createObjectURL(image)} alt="" />) :
                            (<p>Aucune photo sélectionnée</p>)                        
                        }
                    </div>

                </div>

                <input type="text" ref={desc} className={inputErrors.includes('desc') ? 'error': ''} placeholder='Description de la photo' onChange={(e) => { setInputErrors([]); }} />

                <div className="btns-wrapper">
                    <button className='validate' onClick={() => validate()} >Publier ma photo</button>
                    <button className='cancel' onClick={() => setShowAddForm(false) } >Annuler</button>
                </div>

            </div>

        </div>

	);
}

export default AddForm;
