import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useState, createContext } from 'react'

/**    AUTH    */
import Login from './login/Login';
import ChangePwd from './login/ChangePwd';

/**    USER DASHBOARD    */
import Articles from './user_dashboard/Articles';
import Rencontres from './user_dashboard/Rencontres/Rencontres';
import Cart from './user_dashboard/Cart';
import Orders from './user_dashboard/Orders';
import Photos from './user_dashboard/Photos/Photos';

/**    ADMIN DASHBOARD    */
import Users from './admin_dashboard/users/Users';
import Commandes from './admin_dashboard/commandes/Commandes';
import AdminArticles from './admin_dashboard/articles/Articles';
import AdminEvents from './admin_dashboard/events/Events';
import Stats from './admin_dashboard/stats/Stats';
import HomeImages from './admin_dashboard/home_images/HomeImages';

import PageNotFound from './others/PageNotFound';

export const LoginContext = createContext();

function App() {

	let accessToken = localStorage.getItem("accessToken"),
        refreshToken = localStorage.getItem("refreshToken"),
		admin = localStorage.getItem("admin");

	const [loggedIn, setLoggedIn] = useState(accessToken && refreshToken);
	const [isAdmin, setIsAdmin] = useState(admin === "true" ? true : false);
	const [cartCount, setCartCount] = useState(0);

	return (

		<LoginContext.Provider value={{ loggedIn, setLoggedIn, isAdmin, setIsAdmin, cartCount, setCartCount }}>
			<Router>
				<Routes>

					<Route exact path='/' element={< Login />}></Route>
					<Route exact path='/articles' element={< Articles />}></Route>
					<Route exact path='/rencontres' element={< Rencontres />}></Route>
					<Route exact path='/panier' element={< Cart />}></Route>
					<Route exact path='/orders' element={< Orders />}></Route>
					<Route exact path='/photos' element={< Photos />}></Route>
					<Route exact path="/changepwd/:pwdcode" element={< ChangePwd />}></Route>

					<Route exact path='/admin/users' element={< Users />}></Route>
					<Route exact path='/admin/commandes' element={< Commandes />}></Route>
					<Route exact path='/admin/articles' element={< AdminArticles />}></Route>
					<Route exact path='/admin/events' element={< AdminEvents />}></Route>
					<Route exact path='/admin/stats' element={< Stats />}></Route>
					<Route exact path='/admin/images' element={< HomeImages />}></Route>

					<Route path="/admin" element={<Navigate replace to="/admin/users" />} />

					<Route path='*' element={<PageNotFound />}/>

				</Routes>
			</Router>
		</LoginContext.Provider>

	);

}

export default App;
