import { useState, useEffect, useRef } from 'react';
import { useParams, Link } from "react-router-dom";

import Spinner from '../others/Spinner';
import api from "../api_routes/api";

import logoHD from '../../assets/login/logo_GeT_HD.png';

import '../../styles/login/changepwd.scss';

const title = "Changement de mot de passe | Goûts et traditions";
const errorToMsg = {
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.",
    "2": <>Ce lien est invalide ou expiré.<br/>Veuillez recommencer votre demande de changement de mot de passe.</>,
    "pwds_not_equal": "Les deux mots de passe renseignés ne sont pas identiques.",
    "success": <>Votre mot de passe a bien été modifié.<br/>Vous pouvez vous connecter en <Link to='/'>cliquant ici</Link></>
}

function ChangePwd() {

    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState(false);
    const pwd1 = useRef(null), pwd2 = useRef(null);
    const [inputError, setInputError] = useState(false);
    const params = useParams();

    console.log(params);

    useEffect(() => {

        document.title = title;

        const CheckPwdCode = async () => {

            try {

                setIsLoading(true);

                let res = await api.checkPwdCode({pwdcode: params.pwdcode});

                console.log(res);

                if(res?.data?.code === 0) {
                    setCode(0);
                } else if(typeof errorToMsg[res?.data?.code] != "undefined") {
                    setCode(res.data.code);
                } else {
                    setCode(1);
                }

                setIsLoading(false);

            } catch (error) {

                console.log(error);
                setIsLoading(false);

            }

        };

        CheckPwdCode();

    }, []);

    const changePwd = async function() {

        if(isLoading) {
            return;
        }

        if(pwd1.current.value === "") {
            setInputError(true); 
            return;
        }

        if(pwd1.current.value !== pwd2.current.value) {
            setInputError(true);
            alert(errorToMsg["pwds_not_equal"]);
            return;
        }

        setIsLoading(true);

        let res = await api.changePwd({
            pwdcode: params.pwdcode,
            new_pwd: pwd1.current.value
        });

        setIsLoading(false);

        console.log(res);

        if(res?.data?.code === 0) {
            setCode("success");
        } else if(typeof errorToMsg[res?.data?.code] != "undefined") {
            setCode(res.data.code);
        } else {
            setCode(1);
        }

        

    };


	return (

            <div className="changepwd-page">

                <img src={logoHD} alt=""/>

                <h1>Changement de votre mot de passe</h1>

                {isLoading ? (
                    <Spinner />
                ) : (

                    code !== 0 ? 
                    (<p>{errorToMsg[code]}</p>) : 
                    (
                        <div className='changepwd-form'>
                            <input type="password" ref={pwd1} className={inputError ? 'error' : ''} onChange={() => setInputError(currentInputError => { if(currentInputError) return !currentInputError; })} placeholder='Nouveau mot de passe'/>
                            <input type="password" ref={pwd2} className={inputError ? 'error' : ''} onChange={() => setInputError(currentInputError => { if(currentInputError) return !currentInputError; })} placeholder='Nouveau mot de passe'/>
                            <input type="submit" value='Changer mon mot de passe' onClick={changePwd}/>
                        </div>
                    )
                    

                )}

            </div>

	);
}

export default ChangePwd;
