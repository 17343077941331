import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import SiteContainer from './SiteContainer';
import { getBase64FromPicName, updateCart } from "../others/Utils";
import Spinner from '../others/Spinner';
import api from "../api_routes/api";

import { LoginContext } from '../App';

import '../../styles/cart/cart.scss';

const title = "Panier | Goûts et traditions";
const tab = "pan";
const errorToMsg = {
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.",
    "2": "Les stocks disponibles ne permettent pas d'ajouter ce produit au panier.",
    "cart_updated": "Les stocks disponibles ont été modifiés.\nNous avons donc mis à jour votre panier en conséquence."
}

function Cart() {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [cart, setCart] = useState({ count: 0, cart_items: [] });
    const [base64Images, setBase64Images] = useState({});
    const { setCartCount } = useContext(LoginContext);

    const organizeCart = async function(c) {
        
        c.total = 0;
        for(var i=0; i < c.cart_items.length; i++) {

            c.total += c.cart_items[i].stock.price * c.cart_items[i].quantity;

            if(typeof base64Images[c.cart_items[i].stock._id] === "undefined")
                base64Images[c.cart_items[i].stock._id] = await getBase64FromPicName( c.cart_items[i].stock.picName );

        }

        return c;

    }

    useEffect(() => {

        document.title = title;

        const getCart = async () => {

            try {

                setIsLoading(true);

                let res = await api.getCart();

                if(res?.data?.code === 0 && res?.data?.cart) {

                    setCart(await organizeCart(res.data.cart));
                    setBase64Images(base64Images);

                }

                setIsLoading(false);

            } catch (error) {

                console.log(error);
                setIsLoading(false);

            }

        };

        getCart();

    }, []);

    const addToCart = async (articleId, quantity) => {
        
        try {

            let res = await updateCart(articleId, quantity);

            if(res?.data?.code === 0 && res.data?.cart) {

                setCartCount(parseInt(res.data.cart.count));
                setCart(await organizeCart(res.data.cart));
                setBase64Images(base64Images);
                
            } else
                alert(
                    typeof errorToMsg[res?.data?.code] != "undefined" ? 
                        errorToMsg[res.data.code] :
                        errorToMsg["1"]
                );

        } catch (error) {

            console.log(error);
            alert(errorToMsg["1"]);

        }

    };

    const validateCart = async function() {

        try {

            if(window.confirm("Êtes-vous sûr de vouloir valider votre panier ?\nVous ne pourrez plus le modifier par la suite.")) {

                let res = await api.validateCart();

                if(res?.data?.code === 0) {
                    navigate('/orders', {state : {confirm : true}});
                } else if(res.data?.code === 2 && res.data?.cart) {

                    setIsLoading(true);

                    setCart(await organizeCart(res.data.cart));
                    setBase64Images(base64Images);

                    setIsLoading(false);

                    alert(errorToMsg["cart_updated"]);
                    
                } else
                    alert(
                        typeof errorToMsg[res?.data?.code] != "undefined" ? 
                            errorToMsg[res.data.code] :
                            errorToMsg["1"]
                    );

            }

        } catch (error) {

            console.log(error);
            alert(errorToMsg["1"]);

        }
        
    };


	return (

        <SiteContainer tab={tab} >

            <div className="section-panier" id="section-panier">

                <Link to="/orders">Vos anciennes commandes</Link>

                <h1>Votre panier</h1>

                {isLoading ? (
                    <Spinner />
                ) : (
                    cart.count === 0 ?
                    (
                        <div className="empty-cart-wrapper">
                            <p>Votre panier est vide</p>
                            <Link to="/articles">Commencer mes achats</Link>
                        </div>
                    ) :
                    (

                        <>
                            <div className="products-list">

                                <span className="titles-wrapper">
                                    <div className="title">Description</div>
                                    <div className="title">Quantité</div>
                                    <div className="title">Prix</div>
                                </span>

                                {cart.cart_items.map(cart_item => (
                                    
                                    <div className="product-wrapper" key={cart_item._id}>

                                        <div className="product-section desc-wrapper">
                                            <div className="img-wrapper">
                                                <img src={base64Images[cart_item.stock._id]} alt="" />
                                            </div>
                                            <div className="text-wrapper">
                                                <p className="title">{cart_item.stock.title}</p>
                                                <p className="desc">{cart_item.stock.desc}</p>
                                                <p className="price">Prix : {cart_item.stock.price}€</p>
                                            </div>
                                        </div>

                                        <div className="product-section quantity-wrapper">
                                            <div className="moins" onClick={(e) => addToCart(cart_item.stock._id, -1)}>-</div>
                                            <div className='quantity'>{cart_item.quantity}</div>
                                            <div className="plus" onClick={(e) => addToCart(cart_item.stock._id, 1)}>+</div>
                                        </div>

                                        <div className="product-section total-wrapper">
                                            <p className="total"><span>Total : </span>{cart_item.stock.price * cart_item.quantity}€</p>
                                        </div>

                                    </div>

                                ))}

                            </div>

                            <div className="bottom-wrapper">
                                <div className="total-wrapper">Total: <span id="total"></span>{cart.total}€</div>
                                <div className="validate" onClick={(e) => validateCart()}>Valider le panier</div>
                            </div>
                        </>
                    
                    )

                )}

            </div>

        </SiteContainer>

	);
}

export default Cart;
