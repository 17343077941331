import { Link } from 'react-router-dom';
import { useContext } from 'react';

import api from "../api_routes/api";

import { LoginContext } from '../App';

import logoHD_white from '../../assets/login/logo_GeT_HD_white.png';

const errorToMsg = {
    "1" : "Une erreur interne est survenue, veuillez nous excuser pour la gêne occasionnée."
}


function AdminHeader({ tab }) {

    const { setLoggedIn, setIsAdmin } = useContext(LoginContext);

    const logout = async (e) => {

        let refreshToken = localStorage.getItem("refreshToken");
        let res = await api.logout({ refreshToken });

        if(res?.data?.code === 0) {

            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("admin");

            setLoggedIn(false);
            setIsAdmin(false);

        } else 
            alert(errorToMsg["1"]);
        
    };

	return (

        <header>

            <div className="sidebar">

                <img src={logoHD_white} alt="" className = "logo-header" />

                <ul className="menu-wrapper">
                    <Link to="/admin/users"><li>Utilisateurs</li></Link>
                    <Link to="/admin/commandes"><li>Commandes</li></Link>
                    <Link to="/admin/articles"><li>Articles</li></Link>
                    <Link to="/admin/events"><li>Évènements</li></Link>
                    <Link to="/admin/stats"><li>Stats</li></Link>
                    <Link to="/admin/images"><li>Images</li></Link>
                    <Link to="#" onClick={(e) => logout(e)} ><li>Déconnexion</li></Link>
                </ul>

            </div>

            <Link to="/articles"><div className="backToSite">Retour au site</div></Link>

        </header>

    );
}

export default AdminHeader;
