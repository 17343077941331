import axios from "axios";

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
	(config) => {
		const accessToken = localStorage.getItem("accessToken");
		if (accessToken) {
			config.headers["Authorization"] = "JWT " + accessToken;
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {

		const originalRequest = error.config;
		let refreshToken = localStorage.getItem("refreshToken");

		if (
			refreshToken &&
			error.response.status === 401 &&
			!originalRequest._retry
		) {

			originalRequest._retry = true;
			return axios
				.post(`${process.env.REACT_APP_BASE_URL}/auth/getaccesstoken`, { refreshToken: refreshToken })
				.then((res) => {
					if (res.status === 200) {
						localStorage.setItem("accessToken", res.data.accessToken);
						console.log("Access token refreshed!");
						return axios(originalRequest);
					}
				});

		}
		return Promise.reject(error);
	}
);

//functions to make api calls
const api = {

	/** AUTH **/
	login: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, body);
	},
	logout: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/revokerefreshtoken`, body);
	},
	getLoginImgNames: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/auth/getloginimagesnames`);
	},
	sendPwdRequest: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/sendpwdrequest`, body);
	},
	checkPwdCode: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/checkpwdcode`, body);
	},
	changePwd: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/changepwd`, body);
	},

	/** IMAGES **/
	getImg: (fileName) => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/img/${fileName}`, { responseType: 'arraybuffer' });
	},

	/** ARTICLES **/
	getArticles: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/art/getarticles`, body);
	},

	/** RENCONTRES **/
	getEvents: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/ren/getevents`);
	},
	participate: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/ren/participate`, body);
	},
	unsubscribe: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/ren/unsubscribe`, body);
	},

	/** CART **/
	getCart: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/cart/getcart`);
	},
	getCartCount: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/cart/getcartcount`);
	},
	updateCart: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/cart/updatecart`, body);
	},
	validateCart: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/cart/validatecart`);
	},

	/** ORDERS **/
	getOrders: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/ord/getorders`);
	},

	/** PHOTOS **/
	getPhotos: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/pho/get`);
	},
	addPhoto: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/pho/add`, body, {
			headers: {
				'accept': 'application/json',
				'Accept-Language': 'en-US,en;q=0.8',
				'Content-Type': `multipart/form-data; boundary=${body._boundary}`
			}
		});
	},

	/** ADMIN USERS **/
	getUsers: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/adm/getusers`);
	},
	createUser: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/createuser`, body);
	},
	editUser: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/edituser`, body);
	},
	deleteUser: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/deleteuser`, body);
	},

	/** ADMIN COMMANDES **/
	getCommandes: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/adm/getcommandes`);
	},
	updateCommandeStatus: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/updatecommandestatus`, body);
	},
	deleteCommande: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/deletecommande`, body);
	},

	/** ADMIN ARTICLES **/
	getAdminArticles: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/adm/getarticles`);
	},
	createArticle: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/createarticle`, body, {
			headers: {
				'accept': 'application/json',
				'Accept-Language': 'en-US,en;q=0.8',
				'Content-Type': `multipart/form-data; boundary=${body._boundary}`
			}
		});
	},
	editArticle: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/editarticle`, body, {
			headers: {
				'accept': 'application/json',
				'Accept-Language': 'en-US,en;q=0.8',
				'Content-Type': `multipart/form-data; boundary=${body._boundary}`
			}
		});
	},
	deleteArticle: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/deletearticle`, body);
	},

	/** ADMIN EVENTS **/
	getAdminEvents: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/adm/getevents`);
	},
	createEvent: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/createevent`, body, {
			headers: {
				'accept': 'application/json',
				'Accept-Language': 'en-US,en;q=0.8',
				'Content-Type': `multipart/form-data; boundary=${body._boundary}`
			}
		});
	},
	editEvent: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/editevent`, body, {
			headers: {
				'accept': 'application/json',
				'Accept-Language': 'en-US,en;q=0.8',
				'Content-Type': `multipart/form-data; boundary=${body._boundary}`
			}
		});
	},
	deleteEvent: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/deleteevent`, body);
	},

	/** ADMIN STATS **/
	getStats: () => {
		return axios.get(`${process.env.REACT_APP_BASE_URL}/adm/getstats`);
	},

	/** ADMIN HOME IMAGES **/
	editHomeImage: (body) => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}/adm/edithomeimage`, body, {
			headers: {
				'accept': 'application/json',
				'Accept-Language': 'en-US,en;q=0.8',
				'Content-Type': `multipart/form-data; boundary=${body._boundary}`
			}
		});
	},
	
};

export default api;