import { useState, useEffect } from 'react'

import AdminSiteContainer from '../AdminSiteContainer';
import Spinner from '../../others/Spinner';

import api from "../../api_routes/api";

import '../../../styles/admin/stats/stats.scss';

const title = "Admin - Ventes par articles | G&T";
const tab = "ven";

var errorToMsg = {
    "initial_display" : "Une erreur interne est survenue lors de l'affichage des ventes par article, veuillez nous excuser pour la gêne occasionnée.",
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée."
}

function Stats() {

    const [sells, setSells] = useState({
        yearly: {},
        overall: {}
    })
    const [filter, setFilter] = useState("overall");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);


    useEffect(() => {

        document.title = title; 

        const getStats = async () => {

            setIsError(false);
            setIsLoading(true);

            try {


                let res = await api.getStats();
                
                if(res?.data?.code === 0 && res.data?.commandes && res.data?.articles ) {

                    res.data.articles.map(article => {
                        sells.overall[article._id.toString()] = {
                            title: article.title,
                            desc: article.desc,
                            quantity: 0
                        };
                    });

                    res.data.commandes.map(order => {

                        let orderYear = new Date(order.date).getFullYear().toString(); 

                        order.cmd.products.map(article => {

                            if(typeof article.id != "undefined") {

                                if(typeof sells.overall[article.id.toString()] === "undefined") {
                                    sells.overall[article.id.toString()] = {
                                        title: article.title,
                                        desc: article.desc,
                                        quantity: 0
                                    };
                                }

                                sells.overall[article.id].quantity += article.quantity;

                                if(typeof sells.yearly[orderYear] === "undefined") {
                                    sells.yearly[orderYear] = {};
                                }

                                if(typeof sells.yearly[orderYear][article.id.toString()] === "undefined") {
                                    sells.yearly[orderYear][article.id.toString()] = {
                                        title: sells.overall[article.id.toString()].title,
                                        desc: sells.overall[article.id.toString()].desc,
                                        quantity: 0
                                    };
                                }


                                sells.yearly[orderYear][article.id.toString()].quantity += article.quantity;

                            } else 
                                console.log(article);

                        });
                    });
                    
                    console.log("sells : ", sells);

                    setSells(sells);

                } else
                    setIsError(true);

            } catch (error) {

                console.log(error);
                setIsError(true);

            }

            setIsLoading(false);

        }

        getStats();

    }, []);

    const objToJSX = function() {

        if(filter !== "yearly") {

            return (
                Object.keys(sells.overall).map(key => {
                    return (
                        <tr key={key}>
                            <td>
                                <p className='title'>{sells.overall[key].title}</p> 
                                <p className='desc'>{sells.overall[key].desc}</p>
                            </td>
                            <td>{sells.overall[key].quantity}</td>
                        </tr>
                    );
                })
            );
        
        } else {

            return (
                Object.keys(sells.yearly).reverse().map(year => {
                    return [
                        <tr key={year}><td colSpan="2"><p className='year'>{year}</p></td></tr>,
                        Object.keys(sells.yearly[year]).map(key => {
                            return (
                                <tr key={key}>
                                    <td>
                                        <p className='title'>{sells.yearly[year][key].title}</p> 
                                        <p className='desc'>{sells.yearly[year][key].desc}</p>
                                    </td>
                                    <td><p className="qty">{sells.yearly[year][key].quantity}</p></td>
                                </tr>
                            )
                        })
                    ];
                })
            );

        }

    };

	return (

        <AdminSiteContainer>

            <div className="stats-section">

                <h1>Ventes par article</h1>

                {isError && <p className="message">{errorToMsg["initial_display"]}</p>}

                {isLoading ? (
                    <Spinner />
                ) : (

                    <>
                        <select onChange={(e) => { setFilter(e.target.value); } }>
                            <option value="overall">Toutes ventes confondues</option>
                            <option value="yearly">Ventes par année</option>
                        </select>

                        <table>
                            <tbody>
                                <tr>
                                    <th>Article</th>
                                    <th>Quantité</th>
                                </tr>

                                {objToJSX()}

                            </tbody>
                        </table>
                    </>

                )}

            </div>

        </AdminSiteContainer>

	);
}

export default Stats;
