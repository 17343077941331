import { useEffect, useContext } from 'react';
import { Navigate, Link } from 'react-router-dom';

import { LoginContext } from '../App';

import logoHD from '../../assets/login/logo_GeT_HD.png';

import '../../styles/pagenotfound/pagenotfound.scss';

const title = "Page introuvable | Goûts et traditions";

function Cart() {

    const { loggedIn } = useContext(LoginContext);

    useEffect(() => {
        document.title = title;
    }, []);

    if(!loggedIn)
        return <Navigate to="/" />
    else
        return (

            <div className="notfound-page">

                <img src={logoHD} alt="" />

                <h1>La page demandée est introuvable</h1>

                <Link to="/articles">Retour à l'accueil</Link>

            </div>

        );
}

export default Cart;
