import { useState, useEffect } from 'react'

import AdminSiteContainer from '../AdminSiteContainer';
import Spinner from '../../others/Spinner';
import AddForm from './AddForm';
import EditForm from './EditForm';

import api from "../../api_routes/api";
import { getBase64FromPicName, typeToLabel } from "../../others/Utils";

import '../../../styles/admin/articles/articles.scss';

const title = "Admin - Articles | G&T";
const tab = "art";

var errorToMsg = {
    "initial_display" : "Une erreur interne est survenue lors de l'affichage des articles, veuillez nous excuser pour la gêne occasionnée.",
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée."
}

function Articles() {

    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [showAddForm, setShowAddForm] = useState(false);
    const [editForm, setEditForm] = useState({
        show: false,
        article: {}
    });


    useEffect(() => {

        document.title = title; 

        const getArticles = async () => {

            setIsError(false);

            try {

                let res = await api.getAdminArticles();

                if(res?.data?.code === 0 && res.data?.articles) {

                    for(var i=0; i < res.data.articles.length; i++)
                        res.data.articles[i].base64Image = await getBase64FromPicName( res.data.articles[i].picName );

                    setArticles(res.data.articles);

                } else
                    setIsError(true);

            } catch (error) {

                console.log(error);
                setIsError(true);

            }

            setIsLoading(false);

        }

        getArticles();

    }, []);

    const startEditForm = function(article) {

        setEditForm({
            show: true,
            base64Image: article.base64Image, 
            article: {
                _id: article._id,
                type: article.type,
                title: article.title, 
                desc: article.desc, 
                price: (article.price).toString(), 
                quantity: (article.quantity).toString(), 
                hide: (article.hide).toString()
            }
        })

    };

    const deleteArticle = async function(article) {

        if(window.confirm(`Êtes-vous sûr(e) de vouloir supprimer l'article "${article.title}" ?`)) {
            try {

                let res = await api.deleteArticle({ _id: article._id });

                if(res.data.code === 0) {
                    window.location.reload(false);
                } else {
                    
                    if(typeof errorToMsg['' + res.data.code] !== "undefined")
                        alert(errorToMsg['' + res.data.code]);
                    else
                        alert(errorToMsg["1"]);

                }

            } catch (error) {

                console.error(error);
                alert(errorToMsg["1"]);

            }
        }

    };

	return (

        <AdminSiteContainer>

            <div className="articles-section">

                <h1>Articles</h1>

                <button onClick={() => setShowAddForm(true) }>Ajouter un article</button>

                {isError && <p className="message">{errorToMsg["initial_display"]}</p>}

                {isLoading ? (
                    <Spinner />
                ) : (

                    articles.length === 0 ? (
                        <p className="message">{!isError ? "Aucun article" : ""}</p>
                    ) : (

                        <table className = "articles-list">
                            <tbody>

                            <tr>
                                <th></th>
                                <th>Type</th>
                                <th>Titre</th>
                                <th>Description</th>
                                <th>Prix</th>
                                <th>Quantité</th>
                                <th>Caché</th>
                                <th>Actions</th>
                            </tr>

                                {articles.map(article => (

                                    <tr key={article._id}>

                                        <td className='img-wrapper'><img src={article.base64Image} alt="" /></td>
                                        <td>{typeToLabel[article.type]}</td>
                                        <td>{article.title}</td>
                                        <td>{article.desc}</td>
                                        <td>{article.price}€</td>
                                        <td>{article.quantity}</td>
                                        <td>{article.hide ? "Oui" : "Non"}</td>


                                        <td className="actions">

                                            <div className="cross" onClick={() => deleteArticle(article)}>
                                                <div className="mdiv"></div>
                                                <div className="md"></div>
                                            </div>

                                            <div className='edit-btn' onClick={() => startEditForm(article)} >Modifier</div>

                                        </td>

                                    </tr>

                                ))}

                            </tbody>
                        </table>

                    )

                )}

            </div>

            {showAddForm && <AddForm setShowAddForm={setShowAddForm} />}
            {editForm.show && <EditForm editForm={editForm} setEditForm={setEditForm} />}

        </AdminSiteContainer>

	);
}

export default Articles;
