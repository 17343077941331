import { useContext } from 'react'
import { Navigate } from 'react-router-dom';

import AdminHeader from './AdminHeader';

import { LoginContext } from '../App';

import '../../styles/admin/admin-header.scss';

const loginPageUri = "/";
const articlesPageUri = "/articles";

function AdminSiteContainer(props) {

    const { loggedIn, isAdmin } = useContext(LoginContext);

    if(!loggedIn)
        return <Navigate to={loginPageUri} />
    if(!isAdmin)
        return <Navigate to={articlesPageUri} />
    else
        return (
            <>

                <AdminHeader/>

                <div className="page-content">

                    {props.children}

                </div>

            </>
        );

}

export default AdminSiteContainer;
