import api from "../../api_routes/api";

import '../../../styles/admin/users/editform.scss';

var errorToMsg = {
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée."
}

function EditForm({ editForm, setEditForm }) {

    const handleChange = (e) => {
        setEditForm({ ...editForm, [e.target.name]: e.target.value });
    };

    const validate = async () => {

        try {

            let res = await api.editUser({ ...editForm });

            if(res.data.code === 0) {

                window.location.reload(false);

            } else if (res.data.code === 3 && typeof res.data.error != "undefined")
                alert(res.data.error);
            else {
                
                if(typeof errorToMsg['' + res.data.code] !== "undefined")
                    alert(errorToMsg['' + res.data.code]);
                else
                    alert(errorToMsg["1"]);

            }

        } catch (error) {

            console.error(error);
            alert(errorToMsg["1"]);

        }

    };

	return (

        <div className='editform-wrapper user' onClick={() => setEditForm({...editForm, show: false}) } >

            <div className='form-content' onClick={(e) => e.stopPropagation() }>

                <p className="title">Modifier un utilisateur</p>

                <input type = "email" name="email" placeholder = "Email" value={editForm.email} onChange={(e) => handleChange(e)} />
                <input type = "text" name="firstName" placeholder = "Prénom" value={editForm.firstName} onChange={(e) => handleChange(e)} />
                <input type = "text" name="lastName" placeholder = "Nom" value={editForm.lastName} onChange={(e) => handleChange(e)} />
                <label>
                    <input type="checkbox" name="admin" checked={editForm.admin} onChange={(e) => setEditForm({ ...editForm, admin: e.target.checked })} /> Admin
                </label>

                <div className="btns-wrapper">

                    <div className="btn-wrapper">
                        <button onClick={() => validate()}>Valider</button>
                    </div>

                    <div className="btn-wrapper">
                        <button onClick={() => setEditForm({...editForm, show: false}) } >Annuler</button>
                    </div>

                </div>

            </div>

        </div>

	);
}

export default EditForm;
