import { Link } from 'react-router-dom';
import { useEffect, useContext } from 'react';

import api from "../api_routes/api";

import { LoginContext } from '../App';

import logoHD from '../../assets/login/logo_GeT_HD.png';

const errorToMsg = {
    "1" : "Une erreur interne est survenue, veuillez nous excuser pour la gêne occasionnée."
}

export const toggleRespMenu = (open, respMenu, setRespMenu) => {

    if(typeof open === "undefined")
        open = !respMenu.active;

    if(open)
        document.body.classList.add('with--sidebar');
    else 
        document.body.classList.remove('with--sidebar');

    setRespMenu({active : open});

};

function Header({ tab, respMenu, setRespMenu, respContScrollTop }) {

    const { setLoggedIn, isAdmin, setIsAdmin, cartCount, setCartCount } = useContext(LoginContext);

    useEffect(() => {

        const getCartCount = async () => {

            try {

                let res = await api.getCartCount();

                // console.log("res cartcount : ", res);

                if(res?.data?.code === 0 && !isNaN(res.data?.cart_count)) {

                    setCartCount(parseInt(res.data.cart_count));

                }

            } catch (error) {
                console.log(error);
            }

        };

        getCartCount();

    }, []);

    const logout = async (e) => {

        let refreshToken = localStorage.getItem("refreshToken");
        let res = await api.logout({ refreshToken });

        if(res?.data?.code === 0) {

            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("admin");

            setIsAdmin(false);
            setLoggedIn(false);
            

        } else 
            alert(errorToMsg["1"]);
        
    };

	return (

        <header className='user-dashboard'>

            <div id="nav-toggle-container" onClick={(e) => toggleRespMenu(true, respMenu, setRespMenu)}>
                <Link id="nav-toggle" className={ respMenu.active ? "active" : null} to="#"><span></span></Link>
            </div>

            <Link to="/">
                <img 
                    src={logoHD} 
                    alt="" 
                    className="logo-header" 
                    id="logo-header"
                    style={respContScrollTop < 200 ? {opacity : (1 - respContScrollTop / 200)} : {opacity : 0}} 
                />
            </Link>

            <ul className="menu-list">

                <Link to="/" className = "logo-link-resp">
                    <li className = "logo-resp"><img src={logoHD} alt="" /></li>
                </Link>

                <Link to="/articles" className = {tab === "art" ? "current" : ""} onClick={tab === "art" ? (e) => toggleRespMenu(false, respMenu, setRespMenu) : undefined}>
                    <li>Articles</li>
                </Link>

                <Link to="/rencontres" className = {tab === "eve" ? "current" : ""} onClick={tab === "ren" ? (e) => toggleRespMenu(false, respMenu, setRespMenu) : undefined}>
                    <li>Évènements</li>
                </Link>

                <Link to="/photos" className = {tab === "pho" ? "current" : ""} onClick={tab === "pho" ? (e) => toggleRespMenu(false, respMenu, setRespMenu) : undefined}>
                    <li>Photos</li>
                </Link>

                <Link to="/panier" className = {tab === "pan" ? "current" : ""} onClick={tab === "pan" ? (e) => toggleRespMenu(false, respMenu, setRespMenu) : undefined}>
                    <li>Panier<span id="products-counter"> {cartCount > 0 ? `(${cartCount})` : ``}</span></li>
                </Link>

                {isAdmin ? (
                    <Link to="/admin/users">
                        <li>Admin</li>
                    </Link>
                ) : ("")}

                <Link to="#" onClick={(e) => logout(e)}><li>Déconnexion</li></Link>

            </ul>

        </header>

	);
}

export default Header;
