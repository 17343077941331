import { useState } from 'react'

import api from "../../api_routes/api";

import '../../../styles/admin/home_images/editform.scss';

import { MAX_FILE_SIZE_MB } from "../../others/Constants";

var errorToMsg = {
    "1": "Une erreur est survenue lors de la modification de l'image, veuillez nous excuser pour la gêne occasionnée.",
    "file_too_large": `Votre image est trop lourde, veuillez en sélectionner un autre de moins de ${MAX_FILE_SIZE_MB}mb s'il vous plaît.`
};

function EditForm({ editForm, setEditForm }) {

    const [image, setImage] = useState(null);

    const validate = async () => {

        try {

            if(image && (image.size / 1024) > (MAX_FILE_SIZE_MB * 1024)) {
                throw errorToMsg["file_too_large"];
            }

            var form = new FormData();

            form.append("file", image);
            form.append("picture_title", editForm.picture_title);

            let res = await api.editHomeImage(form);

            if(res.data.code === 0) {

                window.location.reload(false);

            } else
                alert(errorToMsg["1"]);

        } catch (error) {

            console.error(error);
            alert(errorToMsg["1"]);

        }

    };


	return (

        <div className='editform-wrapper home-image' onClick={() => setEditForm({...editForm, show: false}) }>

            <div className='form-content' onClick={(e) => e.stopPropagation() }>

                {/* <p className="title">Modifier l'image "{editForm.picture_title}"</p> */}
                <p className="title">Modifier l'image d'accueil</p>

                <div className='img-wrapper'>

                    {image ?
                        (<img src={URL.createObjectURL(image)} alt="" />) :
                        (<p>Aucune image séléctionnée</p>)                        
                    }

                </div>
                
                <div className="input-wrapper">
                    <label htmlFor="file">Choisir une image</label>
                    <p>Extensions supportées : png, jpg, jpeg et gif</p>
                    <input type="file" id="file" accept=".gif,.jpg,.jpeg,.png" onChange={(e) => { setImage(e.target.files[0]); }} />
                </div>

                <div className="btns-wrapper">

                    <div className="btn-wrapper">
                        <button onClick={() => validate()} >Valider</button>
                    </div>

                    <div className="btn-wrapper">
                        <button onClick={() => setEditForm({...editForm, show: false}) } >Annuler</button>
                    </div>

                </div>

            </div>

        </div>

	);
}

export default EditForm;
