import { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';

import SiteContainer from './SiteContainer';
import { formatDateHourFromString } from "../others/Utils";
import Spinner from '../others/Spinner';
import api from "../api_routes/api";

import '../../styles/orders/orders.scss';

const title = "Commandes | Goûts et traditions";
const tab = "pan";
var errorToMsg = {
    "1": "Une erreur est survenue lors de l'affichage de vos commandes, veuillez nous excuser pour la gêne occasionnée."
}

function Orders() {

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [isError, setIsError] = useState(false);

    useEffect(() => {

        document.title = title;

        const getOrders = async () => {

            try {

                setIsLoading(true);

                let res = await api.getOrders();

                if(res?.data?.code === 0 && res?.data?.commandes) {

                    res.data.commandes.map(order => {
                        order.totalPrice = 0;
                        order.cmd.products.map(product => {
                            order.totalPrice += product.price * product.quantity;
                        });
                        return order;
                    });

                    setOrders(res.data.commandes);

                } else {
                    setIsError(true);
                }

            } catch (error) {

                console.log(error);
                setIsError(true);

            }

            setIsLoading(false);

        };

        getOrders();

    }, []);


	return (

        <SiteContainer tab={tab} >

            <div className="section-orders" id="section-orders">

                <h1>Vos commandes</h1>

                {location.state?.confirm && 
                    <p className='confirm'>Votre commande est confirmée !<br/><span>Vous trouverez ci-dessous les détails de votre commande.</span></p>
                }

                {isLoading ? (
                    <Spinner />
                ) : (
                    isError ? <p>{errorToMsg["1"]}</p> :
                        orders.length === 0 ?
                            (<p>Vous n'avez aucune commande pour le moment.</p>) :
                            (

                                orders.map(order => (

                                    <div className="order-wrapper" key={order._id}>

                                        <div className='date'>{formatDateHourFromString(order.date)}</div>
                                        <div className='price'>{order.totalPrice}€</div>

                                        <div className="inner">

                                            <p className='statut'>Statut : <span>{order.statut}</span></p>

                                            <div className="details">
                                                {order.cmd.products.map(product => (
                                                    <p key={product.id}>
                                                        {product.quantity}x {product.title} {product.desc} ({product.price}€)
                                                        <span>{product.quantity * product.price}€</span>
                                                    </p>
                                                ))}
                                            </div>
                                        
                                        </div>

                                    </div>

                                ))
                            )
                )}


            </div>

        </SiteContainer>

	);
}

export default Orders;
