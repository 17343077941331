import { useState } from 'react'

import api from "../../api_routes/api";

import '../../../styles/admin/form.scss';

import { MAX_FILE_SIZE_MB } from "../../others/Constants";

var errorToMsg = {
    "1": "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.",
    "file_too_large": `Votre image est trop lourde, veuillez en sélectionner un autre de moins de ${MAX_FILE_SIZE_MB}mb s'il vous plaît.`
}

function AddForm({ setShowAddForm }) {

    const [formValues, setFormValues] = useState({
        title: "",
        desc: "",
        price: "",
        eventDate: "",
        location: "",
        hide: "false",
        image: null
    });
    const [inputErrors, setInputErrors] = useState([]);
    const [image, setImage] = useState(null);

    const handleChange = (e) => {

        setInputErrors([]);

        if (e.target.files && e.target.files[0]) {

            setImage(URL.createObjectURL(e.target.files[0]));
            setFormValues({ ...formValues, image: e.target.files[0]});

        } else
            setFormValues({ ...formValues, [e.target.name]: (e.target.value).toString() });

    };

    const validate = async () => {

        try {

            let _inputErrors = [],
                form = new FormData();

            for(var key in formValues) {
                if(formValues[key] !== "" && formValues[key] != null) {
                    form.append(key, formValues[key]);
                } else {
                    _inputErrors.push(key);
                }
            }

            if(_inputErrors.length > 0)
                setInputErrors(_inputErrors);
            else if((formValues.image?.size / 1024) > MAX_FILE_SIZE_MB * 1024) {
                alert(errorToMsg["file_too_large"]);
            } else {

                let res = await api.createEvent(form);

                if(res.data.code === 0) {

                    window.location.reload(false);

                } else if (res.data.code === 3 && typeof res.data.error != "undefined")
                    alert(res.data.error);
                else {
                    
                    if(typeof errorToMsg['' + res.data.code] !== "undefined")
                        alert(errorToMsg['' + res.data.code]);
                    else
                        alert(errorToMsg["1"]);

                }

            }

        } catch (error) {

            console.error(error);
            alert(errorToMsg["1"]);

        }

    };


	return (

        <div className='form-wrapper event' onClick={() => setShowAddForm(false) }>

            <div className='form-content' onClick={(e) => e.stopPropagation() }>

                <p className="title">Ajouter un évènement</p>

                <input type = "text" name="title" className={inputErrors.includes('title') ? 'error': ''} placeholder = "Titre" onChange={handleChange} />
                <input type = "text" name="desc" className={inputErrors.includes('desc') ? 'error': ''} placeholder = "Description" onChange={handleChange} />
                <input type = "number" name="price" className={inputErrors.includes('price') ? 'error': ''} min="0" placeholder = "Prix" onChange={handleChange} />
                <input type = "text" name="eventDate" className={inputErrors.includes('eventDate') ? 'error': ''} min="0" placeholder = "Date" onChange={handleChange} />
                <input type = "text" name="location" className={inputErrors.includes('location') ? 'error': ''} min="0" placeholder = "Lieu" onChange={handleChange} />

                <label>
                    <input type="checkbox" name="hide" onChange={(e) => setFormValues({ ...formValues, hide: (e.target.checked).toString() })} /> Caché
                </label>

                <div className={inputErrors.includes('image') ? 'img-handler error': 'img-handler'}>

                    <input type="file" id="file" accept=".gif,.jpg,.jpeg,.png" onChange={handleChange} className="filetype" />

                    <div className="btn-wrapper">
                        <div className="text-wrapper">
                            <label htmlFor="file">Choisir une image</label>
                            <p>Extensions supportées :<br/>png, jpg, jpeg et gif</p>
                        </div>
                    </div>

                    <div className="img-wrapper">
                        {image ?
                            (<img src={image} alt="" />) :
                            (<p>Aucune image séléctionnée</p>)                        
                        }
                    </div>

                </div>

                <div className="btns-wrapper">

                    <div className="btn-wrapper">
                        <button onClick={() => validate()} >Valider</button>
                    </div>

                    <div className="btn-wrapper">
                        <button onClick={() => setShowAddForm(false) } >Annuler</button>
                    </div>

                </div>

            </div>

        </div>

	);
}

export default AddForm;
